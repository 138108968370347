import React from 'react';
import { BsInstagram } from 'react-icons/bs';
import { FaLinkedin, FaGithub } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

const SocialMedia = () => (
  <div className="app__social">
    <div>
      <a href="#home">
        <FaGithub />
      </a>
    </div>
    <div>
      <a href="https://www.linkedin.com/in/priyansh-jadon/">
        <FaLinkedin />
      </a>
    </div>
    <div>
      <a href="https://www.instagram.com/p.j_thakur/">
        <BsInstagram />
      </a>
    </div>
    <div>
      <a href="mailto:hello@micael.com">
        <MdEmail />
      </a>
    </div>
  </div>
);

export default SocialMedia;
