import React, { useContext } from 'react';
import './Toggle.css';
import { ThemeContext } from '../../context/ThemeContext';

const Toggle = () => {
  const { toggle, mode } = useContext(ThemeContext);
  console.log(mode);
  return (
    // <div className="container">
    //   <label className="switch" htmlFor="input">
    //     <input type="checkbox" onClick={toggle} name="input" />
    //     <span className="slider" />
    //   </label>
    // </div>
    <label className="switch-container" htmlFor="input">
      <input type="checkbox" onClick={toggle} />
      <span className="slider" />
    </label>
  );
};

export default Toggle;
